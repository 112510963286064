import React, { useEffect, useState } from 'react'
import PubSub from 'pubsub-js'
import { Link, useRouteMatch } from 'react-router-dom'
import { useDb } from './database'

export default function NextActionsList({ actions }) {
  return actions.map(doc => <Action key={doc._id} doc={doc} actions={actions} />)
}

function Action({ doc, actions }) {
  function clickCheck(ev) {
    if (doc.done) {
      PubSub.publish('UNDONE', { _id: doc._id })
    } else {
      PubSub.publish('DONE', { _id: doc._id })
    }
  }

  return (
    <div style={{ display: 'flex' }}>
      <input
        type="checkbox"
        onChange={clickCheck}
        checked={doc.done || false}
      />
      <div className={`x${doc.type} ${doc.done && 'done'}`} style={{ display: 'flex' }}>
        {doc.pid &&
         <div style={{ paddingRight: '.5em', fontWeight: 'bold' }}>
           <ActionProjectLink action={doc} />
         </div>}
        <ActionLink action={doc} />
      </div>
    </div>
  )
}

function ActionLink({ action }) {
  const { url } = useRouteMatch()
  return <div className="actionx">
    <Link to={`${url}/item/${action._id}`}>{action.name}</Link>
  </div>
}

function ActionProjectLink({ action }) {
  const { url } = useRouteMatch()
  const db = useDb()
  const [project, setProject] = useState()
  useEffect(() => {
    db.get(action.pid).then(project => setProject(project))
  }, [action.pid, db])
  if (!project) {
    return null
  }
  return <div className="projectx">
    <Link to={`${url}/item/${project._id}`}>{project.someday && "someday"} {project.name}</Link>
  </div>
}

