import React, { useEffect, useState } from 'react';
import PubSub from 'pubsub-js';
import InputItem from './InputItem'
import ListItems from "./ListItems";
import { useParams } from 'react-router-dom';
import { useDb } from './database'
import { useFind } from './hooks'

export default function ItemPage() {
  const { id } = useParams()
  const db = useDb()
  const [item, setItem] = useState()
  const [dirty, setDirty] = useState()
  useEffect(() => {
    db.get(id).then(setItem)
  }, [id, dirty, db])
  useEffect(() => {
    if (item) {
      console.log('listen for changes')
      const o = db.changes({
        since: 'now',
        live: true,
      }).on('change', change => {
        console.log({ change })
        setDirty(new Date())
      })
      return () => o.cancel()
    }
  }, [db, item])
  
  console.log("ItemPage", id, item, dirty)
  if (!item) {
    return null
  }

  return <div className={item.type}>
    {item.type === 'action' && <ActionDetail item={item} />}
    {item.type === 'project' && <ProjectDetail item={item} />}
  </div>
}

function ActionDetail({ item, records }) {
  function convertToProject(ev) {
    ev.preventDefault()
    PubSub.publish('SET_TYPE', { _id: item._id, type: 'project' })
  }

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <h3>{item.type}: {item.name}</h3>
        <div>
          <a href="#2" onClick={convertToProject}>convert to project</a>
        </div>
      </div>

      <div>
        Associate with project <SelectProject item={item} records={records} />
      </div>

    </div>
  )
}

function ProjectDetail({ item, records }) {
  function convertToAction(ev) {
    ev.preventDefault()
    PubSub.publish('SET_TYPE', { _id: item._id, type: 'action' })
  }

  const db = useDb()
  const actions = useFind({
    db,
    selector: {
      archived: { $ne: true },
      pid: item._id,
      ca: { $gte: null },
    },
    sort: [ { ca: 'desc' } ],
    limit: 1000,
  })

  function toggleSomeday(ev) {
    ev.preventDefault()
    if (item.someday) {
      PubSub.publish('UNSOMEDAY', { _id: item._id })
    } else {
      PubSub.publish('SOMEDAY', { _id: item._id })
    }
  }

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <h3>
          <a href="#" onClick={toggleSomeday}>{item.someday ? 'someday' : 'project'}</a>
          : {item.name}</h3>
        <div>
          <a href="#1" onClick={convertToAction}>convert to action</a>
        </div>
      </div>

      <InputItem type="action" placeholder="New Project Action" project={item} />
      <ListItems items={actions} />
    </div>
  )
}

function SelectProject({ item, records }) {
  const projects = []//Object.values(records).filter(r => r.type === 'project')
  console.log({ projects })

  function changeProject(x) {
    console.log(x.target.value)
    PubSub.publish('CHANGEITEM', { ...item, pid: x.target.value })
  }

  return (
    <select value={item.pid} onChange={changeProject}>
      <option value="">None</option>
      {projects.map(p => (
        <option key={p._id} value={p._id}>
          {p.name}
        </option>
      ))}
    </select>
  )
}
