import { useState, useRef, useEffect } from 'react'

export function useFind({ db, subscribe = true, ...findArgs }) {
  const [docs, setDocs] = useState([])
  const [dirty, setDirty] = useState()

  const { current: findArgsRef } = useRef(findArgs)

  useEffect(() => {
    const start = performance.now()
    console.log({ findArgsRef })
    db.find(findArgsRef).then(result => {
      setDocs(result.docs)
      console.log(performance.now() - start)
    })
  }, [db, dirty, findArgsRef]);

  useEffect(() => {
    if (subscribe) {
      const o = db.changes({
        live: true,
        since: 'now',
      }).on('change', change => {
        console.log({ change })
        setDirty(Date.now())
      })
      console.log({ o })
      return () => {
        o.cancel()
      }
    }
  }, [db, subscribe])

  return docs
}
