import cryptoRandomString from 'crypto-random-string';

export function getFromLocalStorageWithDefault(key, defaultValue) {
  let value = localStorage.getItem(key)
  if (value) {
    return JSON.parse(value)
  }
  return defaultValue
}

export function lastOrGeneratedDatabaseName() {
  const last = localStorage.getItem("last_database_name")
  if (last) {
    return last
  } else {
    return cryptoRandomString({ length: 7 })
  }
}

export function saveDatabaseName(name) {
  localStorage.setItem("last_database_name", name)

  let table = JSON.parse(localStorage.getItem('databases')) || {}
  table[name] = new Date()
  localStorage.setItem("databases", JSON.stringify(table))
}

export function recentLocalDatabases() {
  return JSON.parse(localStorage.getItem('databases')) || {}
}
