import React from 'react';
import { recentLocalDatabases } from './lastOrGeneratedDatabaseName'
import { Link } from 'react-router-dom';

export default function DatabaseListPage() {
  const dbs = recentLocalDatabases()
  return (
    <>
      <h1>dbs</h1>
      {Object.entries(dbs).map(([name,lua]) => (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div><Link to={`/db/${name}`}>{name}</Link></div>
          <div>(last used {lua})</div>
        </div>
      ))}
    </>
  )
}
