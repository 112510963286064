import React, { useContext, useEffect, useState } from 'react';
import PouchDB from 'pouchdb-browser';
import find from 'pouchdb-find';
import { saveDatabaseName } from "./lastOrGeneratedDatabaseName"

PouchDB.plugin(find);

const DatabaseContext = React.createContext()

export function DatabaseProvider({ name, children }) {
  const [db, setDb] = useState()
  useEffect(() => {
    getDb(name).then(db => setDb(db))
  }, [name])

  useEffect(() => {
    if (db) {
      const remoteDatabaseName = `https://relax.ryanyeske.com/${name}`
      console.log('replicating', remoteDatabaseName)

      const repTo = db.replicate.to(remoteDatabaseName, {
        live: true, retry: true
      })
      const repFrom = db.replicate.from(remoteDatabaseName, {
        live: true, retry: true
      })

      repTo.on('change', function (info) {
        console.log('repTo change', { info })
      })
      repFrom.on('change', function (info) {
        console.log('repFrom change', { info })
      })
      return () => {
        repTo.cancel()
        repFrom.cancel()
      }
    }
  }, [db, name])

  if (db) {
    return <DatabaseContext.Provider value={db} children={children} />
  } else {
    return null
  }
}

export function useDb() {
  return useContext(DatabaseContext)
}

export async function getDb(name) {
  let db = new PouchDB(name)
  saveDatabaseName(name)
  await createIndexes(db)
  return db
}

async function createIndexes(db) {
  console.log('creating indexes...')
  await db.createIndex({ index: { fields: ['ua'] } })
  await db.createIndex({ index: { fields: ['ca'] } })
  console.log('creating indexes...done')
}
