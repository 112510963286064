import React, { useEffect, useState } from 'react';
import InputItem from './InputItem'
import ListItems from './ListItems'
import NextActionsList from './NextActionsList'
import PubSub from 'pubsub-js'
import { BrowserRouter as Router, Redirect, Switch, Route, Link, useRouteMatch } from 'react-router-dom';
import './app.css'
import ItemPage from './ItemPage';
import { useFind } from './hooks';
import { DatabaseProvider, useDb } from './database';
import { lastOrGeneratedDatabaseName } from './lastOrGeneratedDatabaseName';
import DatabaseListPage from "./DatabaseListPage"

export default function App() {
  return (
    <Router>
      <Switch>
        <Route path="/" exact>
          <Redirect to={`/db/${lastOrGeneratedDatabaseName()}`} />
        </Route>
        <Route path="/db" exact>
          <DatabaseListPage />
        </Route>
        <Route path="/db/:dbname" component={({ match: { params: { dbname } } }) => (
          <DatabaseProvider name={dbname}>
            <DbApp />
          </DatabaseProvider>
        )} />
        <Route>
          <h1>404 wut</h1>
        </Route>
      </Switch>
    </Router>
  )
}

function DbApp() {
  const db = useDb()
  useEffect(() => {
    console.log('=== subscribing')

    PubSub.subscribe('REPROCESS', async (msg, data) => {
      const updateDocs = []

      const start = performance.now()
      // make sure all projects that are not part of action are next
      const actions = await db.find({
        selector: {
          type: 'action',
          pid: { $exists: false },
          archived: { $ne: true },
        }
      })
      console.log(performance.now() - start)
      console.log({ actions })
      for (let action of actions.docs) {
        if (!action.next) {
          updateDocs.push({ ...action, next: true })
        }
      }

      // process each active project
      const projects = await db.find({
        selector: {
          type: 'project',
          archived: { $ne: true },
        }
      })
      console.log(performance.now() - start)
      console.log({ projects })
      for (let project of projects.docs) {
        const actions = await db.find({
          selector: {
            type: 'action',
            pid: project._id,
            done: {$ne: true},
            archived: {$ne: true},
          }
        })
        if (actions.docs.length === 0) {
          if (!project.stalled) {
            updateDocs.push({ ...project, stalled: true })
          }
        } else {
          if (project.stalled) {
            updateDocs.push({ ...project, stalled: false })
          }

          // ensure next action
          if (actions.docs.find(a => a.next === true)) {
            //
          } else {
            updateDocs.push({ ...actions.docs[0], next: true })
          }
        }
        console.log(performance.now() - start)
      }
      console.log({ updateDocs })
      console.log(performance.now() - start)

      await db.bulkDocs(updateDocs)
      console.log('REPROCESS TOTAL', performance.now() - start)
    })

    PubSub.subscribe('DONE', async (msg, { _id }) => {
      console.log(msg, { _id })
      const doc = await db.get(_id)
      await db.put({ ...doc, done: true })
      PubSub.publish('REPROCESS')
    })

    PubSub.subscribe('UNDONE', async (msg, { _id }) => {
      console.log(msg, { _id })
      const doc = await db.get(_id)
      delete doc.done
      await db.put(doc)
      PubSub.publish('REPROCESS')
    })

    PubSub.subscribe('SOMEDAY', async (msg, { _id }) => {
      console.log(msg, { _id })
      const doc = await db.get(_id)
      if (doc.type !== 'project') {
        throw new Error(`can only mark projects as someday: _id=${_id}`)
      }
      await db.put({ ...doc, someday: true })
      //PubSub.publish('REPROCESS');
    })

    PubSub.subscribe('UNSOMEDAY', async (msg, { _id }) => {
      console.log(msg, { _id })
      const doc = await db.get(_id)
      if (doc.type !== 'project') {
        throw new Error(`can only mark projects as not someday: _id=${_id}`)
      }
      delete doc.someday
      await db.put(doc)
      //PubSub.publish('REPROCESS');
    })

    PubSub.subscribe('ARCHIVE_DONE', async (msg, data) => {
      console.log(msg, data)

      const { docs } = await db.find({ selector: { done: true, archived: { $ne: true } }})
      const newDocs = docs.map(doc => ({ ...doc, archived: true }));

      const result = await db.bulkDocs(newDocs)
      console.log({ result })
    })

    PubSub.subscribe('UNARCHIVE_UNDONE', async (msg, data) => {
      console.log(msg, data)

      const { docs } = await db.find({ selector: { done: { $ne: true }, archived: true }})
      const newDocs = docs.map(doc => ({ ...doc, archived: false }));

      const result = await db.bulkDocs(newDocs)
      console.log({ result })
    })


    PubSub.subscribe('NEWITEM', (msg, { type, name, project = {} }) => {
      console.warn(msg, { type, name, project })
      const ca = new Date()
      db.post({ type, name, ca, ua: ca, pid: project._id })
      PubSub.publish('REPROCESS')
    })

    PubSub.subscribe('SET_TYPE', async (msg, { _id, type }) => {
      console.log({ msg, _id, type})
      const doc = await db.get(_id)
      await db.put({ ...doc, type })
      PubSub.publish('REPROCESS')
    })

    PubSub.subscribe('CHANGEITEM', (msg, data) => {
      console.error('DEPRECATED', msg, data)
    })

    return () => {
      console.log('=== clearing all subscriptions')
      PubSub.clearAllSubscriptions()
    }
  }, [db])

  const { url, path } = useRouteMatch()
  console.log({ url, path })
  return (
    <div>
      <div style={{ display: 'flex',  alignItems: 'center', justifyContent: 'space-between' }}>
        <h1><Link to={url}>Undone</Link></h1>
        <InputItem type="inbox" placeholder="New Inbox Item" />
      </div>
      <Switch>
        <Route path={path} exact>
          <MainPage />
        </Route>
        <Route path={`${path}/archive`}>
          <ArchivePage />
        </Route>
        <Route path={`${path}/item/:id`}>
          <ItemPage />
        </Route>
      </Switch>
    </div>
  )
}

function MainPage() {
  const db = useDb()

  const inbox = useFind({
    db,
    selector: {
      type: 'inbox',
      archived: { $ne: true },
      ca: { '$gte': null },
    },
    sort: [ { ca: 'desc' } ],
    limit: 1000,
  })

  const actions = useFind({
    db,
    selector: {
      type: 'action',
      next: true,
      archived: { $ne: true },
      ca: { '$gte': null },
    },
    sort: [ { ca: 'desc' } ],
    limit: 1000,
  })

  const projects = useFind({
    db,
    selector: {
      type: 'project',
      stalled: true,
      archived: { $ne: true },
      ca: { '$gte': null },
    },
    sort: [ { ca: 'desc' } ],
    limit: 1000,
  })

  const [doneCount, setDoneCount] = useState(0)

  useEffect(() => {
    setDoneCount(actions.filter(i => i.done && !i.archived).length)
  }, [actions])

  function archive(ev) {
    ev.preventDefault()
    PubSub.publish('ARCHIVE_DONE')
  }

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {doneCount ? <a href="#archive" onClick={archive}>archive {doneCount} items</a> : <span>&nbsp;</span>}
      </div>
      {inbox.length > 0 && (
         <section>
           <h3>Inbox</h3>
           <ListItems items={inbox} />
         </section>
      )}

      {actions.length > 0 && (
         <section>
           <h3>Next Actions</h3>
           <NextActionsList actions={actions} />
         </section>
      )}

      {projects.length > 0 && (
         <section>
           <h3>Projects Needing Action</h3>
           <ListItems items={projects} />
         </section>
      )}
    </div>
  )
}

function ArchivePage() {
  const db = useDb()
  const udocs = useFind({
    db,
    selector: {
      archived: true,
      ua: { '$gte': null },
    },
    sort: [ { ua: 'desc' } ],
    limit: 1000,
  })

  const [doneCount, setDoneCount] = useState(0)

  useEffect(() => {
    setDoneCount(udocs.filter(i => !i.done && i.archived).length)
  }, [udocs])

  function archive(ev) {
    ev.preventDefault()
    PubSub.publish('UNARCHIVE_UNDONE')
  }

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {doneCount ? <a href="#archive" onClick={archive}>unarchive {doneCount} items</a> : <span>&nbsp;</span>}
      </div>
      <ListItems items={udocs} />
    </div>
  )
}
