import React from 'react'
import PubSub from 'pubsub-js'
import { Link, useRouteMatch } from 'react-router-dom'

export default function ListItems({ items }) {
  return items.map(doc => <ListItem key={doc._id} doc={doc} items={items} />)
}

function ListItem({ doc, items }) {
  function clickCheck(ev) {
    if (doc.done) {
      PubSub.publish('UNDONE', { _id: doc._id })
    } else {
      PubSub.publish('DONE', { _id: doc._id })
    }
  }

  let itemRenderer
  if (doc.type === 'action') {
    itemRenderer = <ListAction doc={doc} />
  } else if (doc.type === 'project') {
    itemRenderer = <ListProject doc={doc} items={items} />
  } else if (doc.type === 'inbox') {
    itemRenderer = <ListInbox doc={doc} />
  } else {
    console.error(`unknown type ${doc.type}`)
  }

  return (
    <div style={{ display: 'flex' }}>
      <input
        type="checkbox"
        onChange={clickCheck}
        checked={doc.done || false}
      />
      <div className={`${doc.type} ${doc.done && 'done'}`} style={{ display: 'flex', justifyContent: 'flex-start', width: '100%' }}>
        <div>{itemRenderer}</div>
      </div>
    </div>
  )
}

function ListInbox({ doc }) {
  function click(ev, type) {
    ev.preventDefault()
    PubSub.publish('SET_TYPE', { _id: doc._id, type })
  }
  return <div className="inbox" style={{ display: 'flex' }}>
    <div>
      <a href="#3" className="action" onClick={(ev) => click(ev, 'action')}>action</a>
      {' | '}
      <a href="#4" className="project" onClick={(ev) => click(ev, 'project')}>project</a>
    </div>
    <div style={{ marginLeft: '1em'}}>{doc.name}</div>
  </div>
}

function ListAction({ doc }) {
  const { url } = useRouteMatch()
  return <div className="action">
    <Link to={`${url}/item/${doc._id}`}>{doc.name}</Link>
  </div>
}

function ListProject({ doc, items }) {
  const { url } = useRouteMatch()
  return (
    <div style={{ display: 'flex' }}>
      <div className="project" style={{ marginRight: '.5em' }}>
        <Link to={`${url}/item/${doc._id}`}>{doc.someday && 'SOMEDAY'} {doc.name}</Link>
      </div>
    </div>
  )
}
