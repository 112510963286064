import React from 'react'
import Input from './Input'
import PubSub from 'pubsub-js'

export default function InputCreate({ type, placeholder, onSave, project }) {
  async function submit(value) {
    PubSub.publish('NEWITEM', { type, name: value, project })
  }

  return <Input onSubmit={submit} placeholder={placeholder} />
}
