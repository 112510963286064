import React, { useState } from 'react'

export default function Input({ onSubmit, ...rest }) {
  const [value, setValue] = useState('')

  function submit(ev) {
    ev.preventDefault()
    if (onSubmit) {
      onSubmit(value)
    } else {
      console.warn('no onSubmit prop provided')
    }
    setValue('')
  }

  return (
    <form onSubmit={submit}>
      <input
        type="text"
        value={value}
        onChange={({ target: { value } }) => setValue(value)}
        {...rest}
      />
    </form>
  )
}
